@import "../../node_modules/tailwindcss/base.css";

@import "../../node_modules/tailwindcss/components.css";

@import "../../node_modules/tailwindcss/utilities.css";

html {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.sub-sub-heading {
	@apply text-3xl font-medium;
}

.font-regular {
	@apply font-light;
}
